<template>
	<div class="login">
		<section class="container-fluid flex-column bg-light h-100 p-2 d-flex align-items-center justify-content-center">

			<form @submit.prevent="login">
				<img class="brook-logo" src="@/assets/images/brook-logo-color.png" alt="Brook" />

				<form-row title="Please sign in">
					<form-input
						class="col-md-12"
						type="email"
						name="email"
						label="Email Address"
						v-model="user.username"
					/>

					<form-input
						class="col-md-12"
						password
						name="password"
						label="Password"
						v-model="user.password"
					/>
				</form-row>

				<button type="submit" class="btn btn-primary btn-block mt-2">
					<div v-show="loading" class="spinner-border spinner-border-sm text-light" role="status">
						<span class="sr-only">Loading...</span>
					</div>
					<span :class="loading ? 'btn__loading' : null">Login</span>
				</button>
			</form>

			<alert v-if="error" type="danger" class="mt-3">
				<small>{{ error }}</small>
			</alert>

			<alert v-if="$route.query.action" type="info" class="mt-3">
				<small>You have been securely logged out</small>
			</alert>

		</section>
	</div>
</template>

<script>
// General Components
import formRow from '@/components/form/form-row'
import formInput from '@/components/form/form-input'
import alert from '@/components/general/alert'

export default {
	components: {
		formInput,
		formRow,
		alert
	},
	data() {
		return {
			loading: false,
			error: '',
			user: {
				username: '',
				password: ''
			}
		}
	},
	methods: {
		login() {
			this.loading = true
			
			if ( !!this.user.username && !!this.user.password ) {
				this.$store.dispatch( 'authLogin', this.user)
					.then(() => {
						this.loading = false
						this.$router.push(
							this.$GLOBALS.PATH_DASHBOARD
						)
					})
					.catch((error) => {
						this.loading = false

						if (error.result) {
							// The request was made and the server responded
							this.error = error.result.data['meta']['error']['error_message']
						} else if (error.request) {
							//The request was made but no response was received,
							let errorMessage = JSON.parse(error.request.response)
							errorMessage = errorMessage['meta']['error']['error_message']
							this.error = errorMessage
						} else {
							// Something happened in setting up the request and triggered an Error
							this.error = error.message
						}
					})
			} else {
				this.loading = false

				if ( !this.user.username && !this.user.password ) {
					this.error = 'Please enter a username and password'
				} else if ( !this.user.username ) {
					this.error = 'Please enter a username'
				} else {
					this.error = 'Please enter a password'
				}
			}
		}
	}
}
</script>